import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputField } from '../../../components/Form';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { rEmail } from '../../../constants/validation';

let schema = yup.object().shape({
	email: yup.string().required('E-mail is required').trim().matches( rEmail , 'Email format is incorrect'),
	password: yup.string().required('Password is required'),
});

export const AuthForm = (props) => {
	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
	});

	const { setEmail, setPassword, setMfaDetails } = props;
	const { authenticationService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);

	const onSubmit = async (values) => {
		try {
			setEmail(values.email);
			setPassword(values.password);
			setMfaDetails(await authenticationService.authenticate(values));
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
		}
	};

	return (
		<>
			<Card
				content={
					<div className="card-content p-4">
						<header className="mb-3">
							<h5 className="mb-0 gradient-text">
          Welcome to <br />
							</h5>
							<h4 className="mb-0 gradient-text"><span>Syneto Central</span></h4>
						</header>

						<section className="ml-4 body-section">
							<p className="mb-1">This is where you can:</p>
							<p className="mb-0">
          Manage your Syneto instances, control user access, and oversee multiple sites.
							</p>
						</section>

						<footer className="mt-xxl-5">
							<a
								href="https://syneto.eu/syneto-central/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-decoration-none"
							>
								<Button block className="p-2 border-0 bg-white text-brand-color-dark-40">
            Find out more
								</Button>
							</a>
						</footer>
					</div>
				}
				className="col-5 authentication__container__card rounded-left bg-brand-color-dark-40 text-white"
			/>
			<Card
				content={
					<>
						<div className="d-flex justify-content-between">
							<h5>Log in</h5>

							<a href="https://syneto.eu/support/" target="_blank" rel="noopener noreferrer" className="float-right">
								Need help?
							</a>
						</div>

						<form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
							<InputField {...register('email')} label="Email" errors={errors} />
							<InputField {...register('password')} type="password" label="Password" errors={errors} />
							<Link to="/password-recovery">Forgot password?</Link>
							<div className="d-flex justify-content-between mt-5">
								<Link to="/sign-up">Sign up</Link>
								<Button className="float-right width-96 p-2" type="submit">
									Log in
								</Button>
							</div>
						</form>
					</>
				}
				className="col-7 authentication__container__card rounded-right"
			/>
		</>
	);
};
